// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-cake-js": () => import("./../../../src/pages/about-cake.js" /* webpackChunkName: "component---src-pages-about-cake-js" */),
  "component---src-pages-article-details-js": () => import("./../../../src/pages/article-details.js" /* webpackChunkName: "component---src-pages-article-details-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-home-purchase-js": () => import("./../../../src/pages/home-purchase.js" /* webpackChunkName: "component---src-pages-home-purchase-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rates-js": () => import("./../../../src/pages/rates.js" /* webpackChunkName: "component---src-pages-rates-js" */),
  "component---src-pages-refinance-js": () => import("./../../../src/pages/refinance.js" /* webpackChunkName: "component---src-pages-refinance-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-tools-advice-js": () => import("./../../../src/pages/tools-advice.js" /* webpackChunkName: "component---src-pages-tools-advice-js" */)
}

